<template>
  <el-upload class="image-list-uploader" style="min-width: 460px;" drag :action="action || Constants.upload_url" :show-file-list="true" accept="image/*" list-type="picture-card" :on-success="handleSuccess" :before-upload="beforeUpload" name="file" :file-list="fileList" :on-remove="handleRemove">
    <i class="el-icon-plus"></i>
  </el-upload>
</template>

<script>
import config from "@/common/constants";
export default {
  props: { action: String, imgList: String, isShow: Boolean },
  data() {
    return {
      fileList: []
    };
  },
  mounted() {
    this.initFileList();
  },
  methods: {
    initFileList() {
      if (this.imgList) {
        let v = this.imgList.split("|");
        v.forEach(e => {
          this.fileList.push({ url: this.machiningUrl(e) });
        });
      } else {
        this.fileList = [];
      }
    },
    machiningUrl(v) {
      return v.indexOf("http") == -1 ? config.host + v : v;
    },
    handleRemove(file, fileList) {
      let index = -1;
      this.fileList.forEach((e, i) => {
        if (e.url === file.url) {
          index = i;
        }
      });
      if (index === -1) {
        return false;
      }
      this.fileList.splice(index, 1);
      this.emitImgList();
    },
    handleSuccess(res, file) {
      if (res.code === "0") {
        this.fileList.push({ url: this.machiningUrl(res.data) });
        this.emitImgList();
      }
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    emitImgList() {
      let arr = [];
      this.fileList.forEach(e => {
        let u = e.url;
        if (u.indexOf(config.host) !== -1) {
          u = u.replace(config.host, "");
        }
        arr.push(u);
      });
      this.$emit("uploadImgData", arr.join("|"));
    }
  },
  watch: {
    isShow(v) {
      this.initFileList();
    }
  }
};
</script>