export function timeFmt(time, fmt) {
    if (!time) {
        time = new Date();
    }
    var o = {
        "M+": time.getMonth() + 1,
        "d+": time.getDate(),
        "H+": time.getHours(),
        "m+": time.getMinutes(),
        "s+": time.getSeconds(),
        "q+": Math.floor((time.getMonth() + 3) / 3),
        "S": time.getMilliseconds()
    };
    if (!fmt) {
        fmt = 'yyyy-MM-dd HH:mm:ss'
    }
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (time.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
    }
    return fmt;
};
export function filtiema(time) {
    if (!time) { // 当时间是null或者无效格式时我们返回空
        return ' '
    } else {
        const date = new Date(time) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
        const dateNumFun = (num) => num < 10 ? `0${num}` : num // 使用箭头函数和三目运算以及es6字符串的简单操作。因为只有一个操作不需要{} ，目的就是数字小于10，例如9那么就加上一个0，变成09，否则就返回本身。
        // 这是es6的解构赋值。
        const [Y, M, D, h, m, s] = [
            date.getFullYear(),
            dateNumFun(date.getMonth() + 1),
            dateNumFun(date.getDate()),
            dateNumFun(date.getHours()),
            dateNumFun(date.getMinutes()),
            dateNumFun(date.getSeconds())
        ]
        return `${Y}-${M}-${D} ${h}:${m}:${s}` // 一定要注意是反引号，否则无效。
    }
}

import i18n from '@/components/lang'
export function statusFmt(status) {
    let m = i18n.messages[i18n.locale]['common'];
    return status === 1 ? m['status1'] : m['status0']
}
export function ynFmt(v) {
    let m = i18n.messages[i18n.locale]['common'];
    return v === 1 ? m['yn1'] : m['yn0']
}

import config from '../common/constants'
export function platFmt(v) {
    return config.platform_arr[v];
}
export function imgFmt(v) {
    return v.indexOf('http') == -1 ? (config.host + v) : v;
}


export function contentFmt(content) {
    let div = document.createElement('div');
    div.innerHTML = content;
    let txt = div.innerText;
    return txt.length > 10 ? (txt.substring(0, 10) + '......') : txt;
}