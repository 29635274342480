<template>
  <el-upload class="image-uploader" :action="action || Constants.upload_url" :show-file-list="false" drag accept="image/*" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" name="file" >
    <img v-if="localImg || remoteImage" :src="(localImg || remoteImage) | imgFmt" class="image-uploader-img">
    <div v-else>
      <i class="el-icon-plus image-uploader-icon"></i>
      <span v-if="size" class="image-uploader-tips">{{$t('tips.size') + ': ' + size}}</span>
    </div>
  </el-upload>
</template>

<script>
export default {
  props: { action: String, remoteImage: String, size: String ,idx:Number},
  data() {
    return {
      localImg: undefined,
      uploadImg: undefined
    };
  },
  methods: {
    handleAvatarSuccess(res, file) {
      console.log(file,res,'2000');
      if (res.code === "200") {
        this.localImg = URL.createObjectURL(file.raw)
        this.uploadImg = res.data.fileUrl;
        this.$emit("uploadImgData", res.data,this.idx);
      }
    },
    beforeAvatarUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!isLt1M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      }
      return isLt1M;
    }
  },
  watch: {
    remoteImage() {
      if (this.remoteImage != this.uploadImg) {
        this.localImg = undefined;
      }
    }
  }
};
</script>

<style>
.el-upload--text {
  width: auto !important;
}
.image-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.image-uploader .el-upload:hover {
  border-color: #409eff;
}
.image-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  height: 120px;
  min-width: 120px !important;
  line-height: 120px;
  text-align: center;
}
.image-uploader-icon::before {
  line-height: 120px;
}
.image-uploader-img {
  height: 120px;
  display: block;
}
.image-uploader-tips {
  position: absolute;
  left: 5px;
  bottom: 5px;
}
.el-upload--text{
  height: 120px;
}
</style>
