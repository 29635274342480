export default ({ request }) => ({
    async getStrategyConfig(data) {
      return request({
        url: '/manage/strategyConfig',
        method: 'get',
        data,
      });
    },
    async PostStrategyConfig(data) {
      return request({
        url: '/manage/strategyConfig',
        method: 'POST',
        data,
      });
    },
    async PatchStrategyConfig(data) {
      return request({
        url: '/manage/strategyConfig',
        method: 'Patch',
        data,
      });
    },
    //即使策略事件列表
     getMessageEvent(data) {
      return request({
        url: '/manage/messageEvent/forInstantStrategy',
        method: 'get',
        data,
      });
    },
    //即使策略事件列表
    getPERIODEvent(data) {
      return request({
        url: '/manage/messageEvent',
        method: 'get',
        data,
      });
    },
    async PatchDeveloperParam(data) {
      return request({
        url: '/manage/strategyConfig/developerParam',
        method: 'Patch',
        data,
      });
    },
    async DELETEStrategyConfig(data) {
      return request({
        url: '/manage/strategyConfig',
        method: 'DELETE',
        data,
      });
    },
    exportTemplate() {
      return request({
        url: '/manage/strategyConfig/downloadTemp',
        method: 'get',
      });
    },
    mjbNameFCM() {
      return request({
        url: '/common/getAllMjbNameAndFcmProjectId',
        method: 'get',
      });
    },
    whiteListSave(data) {
      return request({
        url: '/manage/strategyConfig/addStrategyConfig4WhiteList',
        method: 'POST',
        data,
      });
    },
  //策略配置状态更新
  async updateStatus(data) {
    return request({
      url: '/manage/strategyConfig/updateStatus',
      method: 'POST',
      data,
    });
  },
})