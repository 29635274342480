export default {
  route: {
    title: "Management system",
    dashboard: "Dashboard",
    logIn: "Log in",
    register: "Sign up",
    logout: "Logout",
    resetPwd: "resetPwd",
    personalInfo: "personalInfo",
  },
  navbar: {
    logOut: "Log Out",
    dashboard: "Dashboard",
    setting: "Setting",
    user: "User management",
    role: "Role management",
    permission: "Permission management",
    notice: "Notice management",
    noticeList: "Notice list",
    banner: "Banner management",
    bannerList: "Banner list",
    product: "Product management",
    productList: "Product list",
    productType: "Product type",
    gameTag: "Game tag",
    article: "Article management",
    articleList: "Article list",
    bannerType: "Banner type",
    version: "Version control",
    appList: "APP management",
    appVersion: "Version management",
    channel: "Channel management",
    operation: "Operation configuration",
    development: "Development configuration",
    strategyConfig: "strategyConfig",
    sendConfiguration: "sendConfiguration",
    eventManagement: "eventManagement",
    splitterStageRuleRelLimit: "splitterStageRuleRelLimit",
    splitterStageRuleRelSafe: "splitterStageRuleRelSafe",
    messageRecord: "messageRecord",
    strategyDelay: "strategyDelay",
    productSpecialSplitterConfig: "productSpecialSplitterConfig",
    productGlobalSplitterConfig: "productGlobalSplitterConfig",
  },
  login: {
    logIn: "Log in",
    register: "Sign up",
  },
  table: {
    title: "Title",
    titleCn: "Chinese title",
    type: "Type",
    query: "Query",
    loading: "Loading",
    add: "Add",
    status: "Status",
    edit: "Edit",
    delete: "Delete",
    cancel: "Cancel",
    confirm: "Confirm",
    name: "Username",
    password: "Password",
    newPassword: "newPassword",
    userId: "UserID",
    phone: "Phone",
    addTime: "AddTime",
    createTime: "CreateTime",
    operate: "Operate",
    operateStatus: "Enable/Disable",
    roles: "Roles",
    roleName: "Role name",
    roleInfo: "Role introduction",
    dirPermissions: "Directory permissions",
    pagePermissions: "Page permissions",
    interfacePermissions: "Interface permissions",
    iName: "Name",
    iNameCn: "Chinese name",
    method: "Method",
    weight: "Weight/sort",
    choose: "Please choose",
    icon: "Icon",
    parentMenu: "Parent menu",
    iconList: "Icon list",
    content: "Content",
    image: "Image",
    linkUrl: "Link url",
    popularity: "Popularity",
    detail: "Detail",
    info: "Info",
    introduction: "Introduction",
    color: "Color",
    screenshot: "Screenshot",
    recommend: "Recommend",
    banner: "Banner image",
    pageView: "PageViews",
    preservation: "preservation",
    //策略页面表格
    strategyName: "StrategyName",
    event: "Event",
    status: "Status",
    sendContent: "SendContent",
    message_type: "MessageType",
    Development: "DevelopmentParameters",
    strategyCode: "StrategyCode",
    //发送配置
    startTime: "startTime",
    timeOffset: "timeOffset",
  },
  common: {
    status1: "Valid",
    status0: "Invalid",
    yn1: "Yes",
    yn0: "No",
    directory: "Directory",
    page: "Page",
    interface: "Interface",
  },
  rules: {
    name: "Please input Username",
    password: "Please enter your password",
    type: "Please choose the type",
    phone: "Please enter a valid phone number",
    roleName: "Please enter a role name",
    roleInfo: "Please enter a role description",
    perName: "Please enter a permission name",
    perType: "Please enter the permission type",
    title: "Please enter title",
    image: "Please upload an image",
    linkUrl: "Please enter a jump link",
    content: "Please enter content",
    icon: "Please upload an icon",
    info: "Please enter introduction",
  },
  tips: {
    update: "update",
    add: "add",
    delete: "delete",
    deleteConfirm: "Confirm deletion?",
    success: "success",
    error: "Error",
    size: "Recommended size",
  },
  tags: {
    closeOthers: "Close others",
    closeAll: "Close all",
  },
  other: {
    lastTime: "Last login time",
    lastIp: "Last login location",
    backOne: "Back",
    backHome: "Back to dashboard",
    page404: "Ah~ The page you visited does not exist",
    page403: "Ah~ You does not have permission to access this page",
  },
  upload: {
    uploadImg: "Upload image",
    click: "Click to upload",
    errorInfo: "Please wait for all images to upload successfully or have network problems, please refresh the page and re-upload",
  },
};
