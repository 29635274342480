<template>
  <div class="tinymce-container editor-container">
    <textarea class="tinymce-textarea" :id="tinymceId"></textarea>
    <div class="editor-custom-btn-container">
      <editorImage color="#1890ff" class="editor-upload-btn" @successCBK="imageSuccessCBK"></editorImage>
    </div>
  </div>
</template>

<script>
import editorImage from "./components/EditorImage";
import plugins from "./plugins";
import toolbar from "./toolbar";

export default {
  name: "tinymce",
  components: { editorImage },
  props: {
    id: {
      type: String
    },
    value: {
      type: String,
      default: ""
    },
    toolbar: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    menubar: {
      default: "file edit insert view format table"
    },
    height: {
      type: Number,
      required: false,
      default: 360
    },
    isShow: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      hasChange: false,
      hasInit: false,
      tinymceId: "vue-tinymce-" + new Date().getTime()
    };
  },
  computed: {
    lang() {
      return this.$store.getters.language;
    }
  },
  watch: {
    isShow(v) {
      if (v) {
        if (!window.tinymce.get(this.tinymceId)) {
          this.initTinymce();
          this.$nextTick(() => {
            this.setContent(this.value);
          });
        }
      } else {
        this.destroyTinymce();
      }
    }
  },
  mounted() {
    this.initTinymce();
  },
  activated() {
    this.initTinymce();
  },
  deactivated() {
    this.destroyTinymce();
  },
  methods: {
    initTinymce() {
      const _this = this;
      window.tinymce.init({
        selector: `#${this.tinymceId}`,
        height: this.height,
        body_class: "panel-body ",
        object_resizing: false,
        toolbar: this.toolbar.length > 0 ? this.toolbar : toolbar,
        menubar: this.menubar,
        plugins: plugins,
        end_container_on_empty_block: true,
        powerpaste_word_import: "clean",
        code_dialog_height: 450,
        code_dialog_width: 1000,
        advlist_bullet_styles: "square",
        advlist_number_styles: "default",
        imagetools_cors_hosts: ["www.tinymce.com", "codepen.io"],
        default_link_target: "_blank",
        link_title: false,
        language: this.lang === "zh" ? "zh_CN" : "en",
        init_instance_callback: editor => {
          if (_this.value) {
            editor.setContent(_this.value);
          }
          _this.hasInit = true;
          editor.on("NodeChange Change KeyUp SetContent", () => {
            this.hasChange = true;
            this.$emit("input", editor.getContent());
          });
        }
      });
    },
    destroyTinymce() {
      this.setContent('');
      if (window.tinymce.get(this.tinymceId)) {
        window.tinymce.get(this.tinymceId).destroy();
      }
      this.value = '';
    },
    setContent(value) {
      if (window.tinymce.get(this.tinymceId)) {
        window.tinymce.get(this.tinymceId).setContent(value);
      }
    },
    getContent() {
      if(window.tinymce.get(this.tinymceId)){
        window.tinymce.get(this.tinymceId).getContent();
      }
    },
    imageSuccessCBK(arr) {
      const _this = this;
      arr.forEach(v => {
        window.tinymce
          .get(_this.tinymceId)
          .insertContent(`<img class="wscnph" src="${v.url}" >`);
      });
    }
  },
  destroyed() {
    this.destroyTinymce();
  }
};
</script>

<style scoped>
.tinymce-container {
  position: relative;
  min-width: 600px;
}
.tinymce-container >>> .mce-fullscreen {
  z-index: 10000;
}
.tinymce-textarea {
  visibility: hidden;
  z-index: -1;
}
.editor-custom-btn-container {
  position: absolute;
  right: 4px;
  top: 1px;
  /*z-index: 2005;*/
}
.editor-upload-btn {
  display: inline-block;
}
</style>
