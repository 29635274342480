<template>
  <div class="pick-box">
    <el-date-picker v-model="dateGroup" :type="date.timeUnit == 'month' ? 'monthrange' : 'daterange'" align="right" unlink-panels range-separator="至" start-placeholder="开始"
      end-placeholder="结束" style="margin-right:10px" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" @change="pickChange">
    </el-date-picker>
    <el-radio-group v-model="date.timeUnit" @change="pickChange" v-if="showType">
      <el-radio label="day">天</el-radio>
      <el-radio label="week">周</el-radio>
      <el-radio label="month">月</el-radio>
    </el-radio-group>
  </div>
</template>

<script>
var pickType = "";
import { timeFmt } from "@/filters"
export default {
  props: {
    param: {
      type: String
    },
    showType: {
      type: Boolean
    },
    default: {
      type: Boolean
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.default) {
        if (this.date.timeUnit == 'day') {
          this.dateGroup = [timeFmt(), timeFmt()];
        }
        this.emitParent();
      }
    });
  },
  data() {
    return {
      date: {
        startTime: undefined,
        endTime: undefined,
        timeUnit: "day"
      },
      dateGroup: [],
      pickerOptions: {
        disabledDate(time) {
          let d = new Date();
          d.setHours(23);
          d.setMinutes(59);
          d.setSeconds(59);
          d.setMilliseconds(0);
          return time.getTime() > d.getTime();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              pickType = "day";
              picker.$emit("pick", [new Date(), new Date()]);
            }
          },
          {
            text: "最近一周",
            onClick(picker) {
              pickType = "day";
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              pickType = "day";
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "本周",
            onClick(picker) {
              pickType = "day";
              const start = new Date();
              var weekday = start.getDay() || 7;
              start.setDate(start.getDate() - weekday + 1);
              picker.$emit("pick", [start, new Date()]);
            }
          }
        ]
      }
    };
  },
  methods: {
    pickChange() {
      console.log("aaa");
      if (pickType) {
        this.date.timeUnit = pickType;
        pickType = "";
      }
      this.emitParent();
    },
    emitParent() {
      if (this.dateGroup && this.dateGroup[0]) {
        this.date.startTime = this.dateGroup[0];
        this.date.endTime = this.dateGroup[1];
      } else {
        this.date.startTime = undefined;
        this.date.endTime = undefined;
      }
      this.$parent[this.param].startTime = this.date.startTime;
      this.$parent[this.param].endTime = this.date.endTime;
      this.$parent[this.param].timeUnit = this.date.timeUnit;
    }
  },
  watch: {
    dateGroup() {
      if (this.$parent[this.param].startTime != this.date.startTime || this.$parent[this.param].endTime != this.date.endTime || this.$parent[this.param].timeUnit != this.date.timeUnit) {
        this.emitParent();
      }
    }
  }
};
</script>

<style lang="less" scoped>
.pick-box {
  margin: 0 10px 0 10px;
  display: inline-block;
}
</style>
