export default {
  route: {
    title: "管理系统",
    dashboard: "首页",
    logIn: "登录",
    register: "注册",
    logout: "注销",
    resetPwd: "重置密码",
    personalInfo: "个人信息",
  },
  navbar: {
    logOut: "退出登录",
    dashboard: "首页",
    setting: "系统设置",
    user: "用户管理",
    role: "角色管理",
    permission: "权限管理",
    notice: "公告管理",
    noticeList: "公告列表",
    banner: "Banner管理",
    bannerList: "Banner列表",
    product: "产品管理",
    productList: "产品列表",
    productType: "产品类型",
    gameTag: "游戏标签",
    article: "文章管理",
    articleList: "资讯/攻略列表",
    bannerType: "Banner类型",
    version: "版本控制",
    appList: "APP管理",
    appVersion: "版本管理",
    channel: "渠道管理",
    operation: "运营配置",
    development: "开发配置",
    strategyConfig: "策略配置",
    sendConfiguration: "发送配置",
    eventManagement: "事件管理",
    splitterStageRuleRelLimit: "分流规则关联配置-日限量",
    splitterStageRuleRelSafe: "分流规则关联配置-前置拦截",
    messageRecord: "消息发送记录",
    strategyDelay: "周期性消息记录",
    channelAmountConfirmMode: "渠道额度确认模式配置",
    dataQuery: "数据查询",
    userInfo: "用户信息",
    withdrawalApplication: "提现申请",
    billInfo: "账单信息",
    shuntResults: "分流推荐结果",
    publicInfo: "用户公共资料",
    equipmentInfo: "设备信息",
    equipment: "设备查询",
    productSpecialSplitterConfig: "特殊产品分流配置",
    productGlobalSplitterConfig: "API分流全局配置",
  },
  table: {
    title: "标题",
    titleCn: "中文标题",
    type: "类型",
    query: "查询",
    loading: "加载中",
    add: "添加",
    status: "状态",
    edit: "编辑",
    delete: "删除",
    cancel: "取 消",
    confirm: "确 定",
    name: "账号",
    password: "密码",
    newPassword: "新密码",
    userId: "用户ID",
    phone: "电话",
    addTime: "添加时间",
    createTime: "创建时间",
    operate: "操作",
    operateStatus: "启用/停用",
    roles: "角色",
    roleName: "角色名",
    roleInfo: "角色介绍",
    dirPermissions: "目录权限",
    pagePermissions: "页面权限",
    interfacePermissions: "接口权限",
    iName: "名称",
    iNameCn: "中文名称",
    method: "接口",
    weight: "权重/排序",
    choose: "请选择",
    icon: "图标",
    parentMenu: "父级菜单",
    iconList: "图标列表",
    content: "内容",
    image: "图片",
    linkUrl: "链接地址",
    popularity: "人气",
    detail: "详情",
    info: "介绍",
    introduction: "简介",
    color: "颜色",
    screenshot: "截图",
    recommend: "是否推荐",
    banner: "Banner图",
    pageView: "浏览量",
    preservation: "保存",
    //策略页面表格
    strategyName: "策略名称",
    event: "事件",
    status: "状态",
    sendContent: "发送内容",
    message_type: "发送方式",
    Development: "更新开发相关参数",
    strategyCode: "策略代码",
    //发送配置
    startTime: "开始时间（时：分）",
    timeOffset: "策略方案",
  },
  common: {
    status1: "有效",
    status0: "无效",
    yn1: "是",
    yn0: "否",
    directory: "目录",
    page: "页面",
    interface: "接口",
  },
  rules: {
    name: "请输入账号",
    password: "请输入密码",
    type: "请选择类型",
    phone: "请输入正确的手机号",
    roleName: "请输入角色名",
    roleInfo: "请输入角色介绍",
    perName: "请输入权限名称",
    perType: "请输入权限类型",
    title: "请输入标题",
    image: "请上传图片",
    linkUrl: "请输入跳转链接",
    content: "请输入内容",
    icon: "请上传图标",
    info: "请输入介绍",
  },
  tips: {
    update: "更新",
    add: "添加",
    delete: "删除",
    deleteConfirm: "确认删除？",
    success: "成功",
    error: "错误",
    size: "推荐尺寸",
  },
  tags: {
    closeOthers: "关闭其它",
    closeAll: "关闭所有",
  },
  other: {
    lastTime: "上次登录时间",
    lastIp: "上次登录地点",
    backOne: "返回上一页",
    backHome: "返回首页",
    page404: "啊哦~ 你所访问的页面不存在",
    page403: "啊哦~ 你没有权限访问该页面哦",
  },
  upload: {
    uploadImg: "上传图片",
    click: "点击上传",
    errorInfo: "请等待所有图片上传成功或出现了网络问题，请刷新页面重新上传",
  },
  error: {
    e904: "服务器开小差了~",
    e104: "账户名或密码错误",
    e401: "无权限",
    e403: "非法权限",
    e405: "无操作权限",
    e402: "参数错误",
  },
};
