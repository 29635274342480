export default ({ request }) => ({
  //发送配置列表
  async messageSendTask(data) {
    return request({
      url: "/manage/messageSendTask",
      method: "get",
      data,
    });
  },
  //添加配置列表
  async POSTmessageSendTask(data) {
    return request({
      url: "/manage/messageSendTask",
      method: "POST",
      data,
    });
  },
  //删除配置列表
  async DELETEmessageSendTask(data) {
    return request({
      url: "/manage/messageSendTask",
      method: "DELETE",
      data,
    });
  },
  //更新配置列表
  async PATCHmessageSendTask(data) {
    return request({
      url: "/manage/messageSendTask",
      method: "PATCH",
      data,
    });
  },
  //更新配置列表
  async GETstrategyGroup(data) {
    return request({
      url: "/manage/strategyGroup",
      method: "get",
      data,
    });
  },

  //特殊产品分流配置
  async GetProductConfig(methods, data) {
    return request({
      url: "/manage/productSpecialSplitterConfig",
      method: methods,
      data,
    });
  },
  //API分流全局配置
  async productGlobalConfig(methods, data) {
    return request({
      url: "/manage/productGlobalSplitterConfig",
      method: methods,
      data,
    });
  },
  //产品列表
  async GetProductList(id) {
    return request({
      url: `/manage/credit/productList/${id}`,
      method: "get",
    });
  },
});
