import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
    },
    {
      path: "/",
      component: (r) => require.ensure([], () => r(require("@/views/common/Home")), "commonPage"),
      meta: {
        title: "home",
      },
      children: [
        {
          path: "/dashboard",
          component: (r) => require.ensure([], () => r(require("@/views/common/Dashboard")), "commonPage"),
          meta: {
            title: "dashboard",
          },
        },
        // 系统设置
        {
          path: "/user",
          component: (r) => require.ensure([], () => r(require("@/views/admin/User")), "adminPage"),
          meta: {
            title: "user",
            permission: true,
          },
        },
        {
          path: "/role",
          component: (r) => require.ensure([], () => r(require("@/views/admin/Role")), "adminPage"),
          meta: {
            title: "role",
            permission: true,
          },
        },
        {
          path: "/permission",
          component: (r) => require.ensure([], () => r(require("@/views/admin/Permission")), "adminPage"),
          meta: {
            title: "permission",
            permission: true,
          },
        },
        //策略配置
        {
          path: "/strategyConfig",
          component: (r) => require.ensure([], () => r(require("@/views/pages/strategyConfig/index")), "operation"),
          meta: {
            title: "strategyConfig",
            permission: true,
          },
        },
        //发送配置
        {
          path: "/sendConfiguration",
          component: (r) => require.ensure([], () => r(require("@/views/pages/sendConfiguration/index.vue")), "operation"),
          meta: {
            title: "sendConfiguration",
            permission: true,
          },
        },
        //事件管理
        {
          path: "/eventManagement",
          component: (r) => require.ensure([], () => r(require("@/views/pages/eventManagement/index.vue")), "operation"),
          meta: {
            title: "eventManagement",
            permission: true,
          },
        },
        //消息发送记录
        {
          path: "/messageRecord",
          component: (r) => require.ensure([], () => r(require("@/views/pages/MessageRecord.vue")), "operation"),
          meta: {
            title: "messageRecord",
            permission: true,
          },
        },
        //周期性消息记录
        {
          path: "/strategyDelay",
          component: (r) => require.ensure([], () => r(require("@/views/pages/StrategyDelay.vue")), "operation"),
          meta: {
            title: "strategyDelay",
            permission: true,
          },
        },
        //channelAmountConfirmMode
        {
          path: "/channelAmountConfirmMode",
          component: (r) => require.ensure([], () => r(require("@/views/pages/channelAmountConfirmMode/index.vue")), "operation"),
          meta: {
            title: "channelAmountConfirmMode",
            permission: true,
          },
        },
        {
          path: "/productSpecialSplitterConfig",
          component: (r) => require.ensure([], () => r(require("@/views/pages/productSpecialSplitterConfig/index.vue")), "operation"),
          meta: {
            title: "productSpecialSplitterConfig",
            permission: true,
          },
        },
        //API分流全局配置
        {
          path: "/productGlobalSplitterConfig",
          component: (r) => require.ensure([], () => r(require("@/views/pages/productGlobalSplitterConfig/index.vue")), "operation"),
          meta: {
            title: "productGlobalSplitterConfig",
            permission: true,
          },
        },
        //分流规则关联配置-日限量
        {
          path: "/splitterStageRuleRelLimit",
          component: (r) => require.ensure([], () => r(require("@/views/pages/SplitterStageRuleRelLimit.vue")), "operation"),
          meta: {
            title: "splitterStageRuleRelLimit",
            permission: true,
          },
        },
        //分流规则关联配置-前置拦截
        {
          path: "/splitterStageRuleRelSafe",
          component: (r) => require.ensure([], () => r(require("@/views/pages/SplitterStageRuleRelSafe.vue")), "development"),
          meta: {
            title: "splitterStageRuleRelSafe",
            permission: true,
          },
        },

        //用户信息
        {
          path: "/userInfo",
          component: (r) => require.ensure([], () => r(require("@/views/pages/dataquery/userInfo.vue")), "dataQuery"),
          meta: {
            title: "userInfo",
            permission: true,
          },
        },
        //提现申请
        {
          path: "/withdrawalApplication",
          component: (r) => require.ensure([], () => r(require("@/views/pages/dataquery/withdrawalApplication.vue")), "dataQuery"),
          meta: {
            title: "withdrawalApplication",
            permission: true,
          },
        },
        //账单信息
        {
          path: "/billInfo",
          component: (r) => require.ensure([], () => r(require("@/views/pages/dataquery/billInfo.vue")), "dataQuery"),
          meta: {
            title: "billInfo",
            permission: true,
          },
        },
        //分流推荐结果
        {
          path: "/shuntResults",
          component: (r) => require.ensure([], () => r(require("@/views/pages/dataquery/shuntResults.vue")), "dataQuery"),
          meta: {
            title: "shuntResults",
            permission: true,
          },
        },
        //用户公共资料
        {
          path: "/publicInfo",
          component: (r) => require.ensure([], () => r(require("@/views/pages/dataquery/publicInfo.vue")), "dataQuery"),
          meta: {
            title: "publicInfo",
            permission: true,
          },
        },
        //设备信息查询
        {
          path: "/equipment",
          component: (r) => require.ensure([], () => r(require("@/views/pages/equipment.vue")), "equipmentInfo"),
          meta: {
            title: "equipment",
            permission: true,
          },
        },
      ],
    },
    {
      path: "/login",
      component: (r) => require.ensure([], () => r(require("@/views/common/Login")), "commonPage"),
    },
    {
      path: "/404",
      component: (r) => require.ensure([], () => r(require("@/views/common/404")), "commonPage"),
    },
    {
      path: "/403",
      component: (r) => require.ensure([], () => r(require("@/views/common/403")), "commonPage"),
    },
    {
      path: "*",
      redirect: "/404",
    },
  ],
});
