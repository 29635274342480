<template>
  <el-upload ref="upload" class="file-uploader" :action="uploadData.action || Constants.upload_url" drag name="file" 
  :accept="uploadData.accept" :data="{path: uploadData.path}" :limit="2" :multiple="false" :auto-upload="true" :on-success="handleSuccess"  
  :before-upload="beforeUpload" :on-change="changeHandle" :on-preview="previewHandle" :disabled='false'>
  <div>
    <div></div>
    <el-button size="mini"  type="primary">选择文件</el-button>&nbsp;&nbsp;
    <span slot="tip" class="el-upload__tip">请上传excel文件，文件大小小于5MB <b @click.stop="downloadTemplate">示例.excel</b></span>
  </div>
    <!-- <i v-if="uploadStatus >= 3" class="el-icon-success"></i>
    <i v-else class="el-icon-upload"></i>
    <div v-if="uploadStatus >= 3" class="el-upload__text">已上传</div>
    <div v-else class="el-upload__text">将文件拖到此处，或
      <em>点击选择</em>
    </div>
    <el-button size="small" :type="type" @click="submitUpload">{{info}}</el-button> -->
    <!-- {{ paramsData }} -->
  </el-upload>
</template>

<script>
import API from "@/api";
export default {
  props: ["uploadData", "isShow",'paramsData'],
  data() {
    return {
      uploadStatus: 1,
      statusInfo: {
        1: { type: "info", info: "等待选择文件" },
        2: { type: "primary", info: "已选择，点击上传" },
        3: { type: "success", info: "已上传，重新选择" }
      }
    };
  },
  computed: {
    type() {
      return this.statusInfo[this.uploadStatus > 3 ? 3 : this.uploadStatus]
        .type;
    },
    info() {
      return this.statusInfo[this.uploadStatus > 3 ? 3 : this.uploadStatus]
        .info;
    }
  },
  methods: {
    handleSuccess(res, file) {
      if (res.code !=="200") {
        this.$message.error(res.msg);
        this.$refs.upload.clearFiles();
        return
        // this.uploadStatus = 4;
        // this.$emit("uploadFileData", res.data);
      }
      console.log(res,file);
      this.$emit("uploadFileData", res.data);
    },
    beforeUpload(file) {
      const size = file.size / 1024 / 1024 < 5;
      if (!size) {
        this.$message.error("大小不能超过 5MB!");
      }
      return size;
    },
    submitUpload(event) {
      if (this.uploadStatus === 2) {
        this.$refs.upload.submit();
        event.stopPropagation();
      }
      if (this.uploadStatus === 4) {
        this.uploadStatus = 3;
      }
    },
    changeHandle(file, list) {
      if (list.length > 1) {
        list.shift();
      }
      if (list.length > 0) {
        if (this.uploadStatus === 1) {
          this.uploadStatus = 2;
        }
        if (this.uploadStatus === 3) {
          this.uploadStatus = 2;
        }
      }
    },
    previewHandle(file) {
    },
    submitInfo(){
      this.$refs.upload.submit()
    },
    /* 模版下载 */
    downloadTemplate(){
      API.exportTemplate().then(res=>{
        const data=res.data
        if (data.code !=="200") {
        this.$message.error(res.msg);
        return
      }
        location.href=data.data.url
      })
    },
  },
  watch: {
    isShow() {
      alert(this.isShow)
      if (!this.isShow) {
        this.$refs.upload.clearFiles();
        this.uploadStatus = 1;
      } else {
        if (this.uploadData.fileData != undefined) {
          this.uploadStatus = 3;
        }
      }
    }
  }
};
</script>

<style>
.file-uploader .el-upload--text {
  border: none !important;
  height: auto !important;
}
.file-uploader .el-icon-success {
  font-size: 67px;
  color: #67c23a;
  margin: 40px 0 16px;
  line-height: 50px;
}
.file-uploader b{
  color: rgb(22, 165, 242);
  cursor: pointer;
  text-decoration: underline;
}
</style>
