import axios from "axios";
// import Adapter from 'axios-mock-adapter';
import { get } from "lodash";
import cookies from "js-cookie";
import Qs from "qs";
import { Message } from "element-ui";

const _base_url = process.env.NODE_ENV === "development" ? "/api" : window.location.origin + "/mapi";
/**
 * @description 创建请求实例
 */
function createService() {
  // 创建一个 axios 实例
  const service = axios.create();
  // 请求拦截
  service.interceptors.request.use(
    (req) => req,
    (error) => {
      // 发送失败
      console.log(error);
      return Promise.reject(error);
    }
  );
  // 响应拦截
  service.interceptors.response.use(
    (res) => {
      if (res.data.code !== "200") {
        let msg = res.data.msg;
        Message({
          message: msg,
          type: "error",
          duration: 3 * 1000,
        });
        return Promise.reject(res);
      } else {
        return res;
      }
    },
    (error) => {
      const status = get(error, "response.status");
      1;
      switch (status) {
        case 400:
          error.message = "请求错误";
          break;
        case 401:
          error.message = "未授权，请登录";
          break;
        case 403:
          error.message = "拒绝访问";
          break;
        case 404:
          error.message = `请求地址出错: ${error.response.config.url}`;
          break;
        case 408:
          error.message = "请求超时";
          break;
        case 500:
          error.message = "服务器内部错误";
          break;
        case 501:
          error.message = "服务未实现";
          break;
        case 502:
          error.message = "网关错误";
          break;
        case 503:
          error.message = "服务不可用";
          break;
        case 504:
          error.message = "网关超时";
          break;
        case 505:
          error.message = "HTTP版本不受支持";
          break;
        default:
          break;
      }
      return Promise.reject(error);
    }
  );
  return service;
}

/**
 * @description 创建请求方法
 * @param {Object} service axios 实例
 */
function createRequestFunction(service) {
  return function(config, postget = false) {
    const token = cookies.get("auth");
    // const token = 'test_token'
    const configDefault = Object.assign(
      {
        headers: {
          Authorization: token,
          "Content-Type": get(config, "headers.Content-Type", "application/json"),
        },
        timeout: 300000,
        baseURL: _base_url,
        data: {},
      },
      config
    );
    if (config.method.toLowerCase() === "patch" || config.method.toLowerCase() === "post") {
      configDefault.data = Qs.stringify(config.data);
      configDefault.params = {};
      configDefault.headers["Content-Type"] = "application/x-www-form-urlencoded";
    } else if (config.method.toLowerCase() === "get" || config.method.toLowerCase() === "delete") {
      configDefault.params = config.data;
    }

    return service(configDefault);
  };
}

// 用于真实网络请求的实例和请求方法
export const service = createService();
export const request = createRequestFunction(service);

// 用于模拟网络请求的实例和请求方法
export const serviceForMock = createService();
export const requestForMock = createRequestFunction(serviceForMock);

// 网络请求数据模拟工具
// export const mock = new Adapter(serviceForMock);
