import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import "babel-polyfill"
import i18n from './components/lang'
Vue.use(ElementUI, {
  size: 'small', i18n: (key, value) => i18n.t(key, value)
});

Vue.prototype.$axios = axios;

Vue.config.productionTip = false

import * as filters from './filters'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
Vue.use(preview, {
  fullscreenEl: false,
  bgOpacity: 0.7,
  errorMsg: '加载失败',
  showAnimationDuration: 0
});

import constants from './common/constants'
Vue.prototype.Constants = constants;

import utils from './utlis/utlis'
Vue.prototype.utils = utils

import ImgUpload from "./components/upload/image";
import ImgListUpload from "./components/upload/image-list";
import FileUpload from "./components/upload/file";
import Tinymce from "./components/tinymce";
import DateInput from "./components/date-input";
Vue.use(ImgUpload);
Vue.use(ImgListUpload);
Vue.use(FileUpload);
Vue.use(Tinymce);
Vue.use(DateInput);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')